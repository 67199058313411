
import React from 'react'
import { Box } from "@mui/material";
const AboutPage = () => {
  return (
    <>
    <Box height={100}></Box>
    <div>AboutPage</div></>
  )
}

export default AboutPage;