import { Box, Container, CssBaseline, Typography } from '@mui/material';
import React from 'react';
import StudentsList from './studentslist';
import DataTable from './studentslist';

const Students = () => {
  return (
    <>
    {/* <Box height={10}></Box>
    <Box component="main" sx={{flexGrow:1,p:3}}>
    <h1>Students</h1>
    <Container>
      <CssBaseline/>
      <StudentsList></StudentsList>
      </Container>
    </Box> */}
     <Container>
      <CssBaseline />
      <Typography variant="h4" gutterBottom>
        Student Data Table
      </Typography>
      <DataTable/>
      {/* <StudentsList></StudentsList> */}
    </Container>
    </>
  )
}

export default Students