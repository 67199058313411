import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
  Box,
} from '@mui/material';

const DataTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://localhost:7162/api/Student');
        setData(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      { Header: 'Student ID', accessor: 'studentID' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Father\'s Name', accessor: 'fatherName' },
      { Header: 'Admission Class', accessor: 'admisionClass' },
      { Header: 'Current Class', accessor: 'currentClass' },
      { Header: 'Date of Birth', accessor: 'dob' },
      { Header: 'Date of Joining', accessor: 'doj' },
      { Header: 'Contact', accessor: 'contact' },
      { Header: 'Address', accessor: 'address' },
      { Header: 'Active', accessor: 'isActive' },
      { Header: 'Total Fee Paid', accessor: 'totalFeePaid' },
      { Header: 'Fee Due', accessor: 'feeDue' },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize, globalFilter } = state;

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TextField
        value={globalFilter || ''}
        onChange={e => setGlobalFilter(e.target.value)}
        placeholder={`Search ${preGlobalFilteredRows.length} records...`}
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <Box sx={{ width: '100%', overflow: 'auto' }}>
        <TableContainer sx={{ maxHeight: '70vh', overflow: 'auto' }}>
          <Table {...getTableProps()}>
            <TableHead>
              {headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <TablePagination
        component="div"
        count={rows.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        onPageChange={(e, newPage) => gotoPage(newPage)}
        onRowsPerPageChange={e => setPageSize(Number(e.target.value))}
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
      />
    </Paper>
  );
};

export default DataTable;
