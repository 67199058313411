import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import HomePage from "./pages/home";
import SettingsPage from "./pages/settings";
import AboutPage from "./pages/about";
import SideNav from "./componets/sidenav";


function App() {
  return (
    // <BrowserRouter>
    //   <Routes>
    //     <Route path="/" element={<HomePage/>}/>
    //     <Route path="/settings" element={<SettingsPage/>}/>
    //     <Route path="/about" element={<AboutPage></AboutPage>}/>
        
    //   </Routes>
    // </BrowserRouter>
    <>
    <SideNav/>
    </>
  );
}

export default App;
