import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

import { Box, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
const HomePage = () => {
  return (
   <>
      
      <Box height={100} style={{color:"red"}}></Box>
      <Grid container spacing={2} border={1}>
        <Grid item xs={8} >
         <p>Hello</p>
         <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image="/static/images/cards/contemplative-reptile.jpg"
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Lizard
          </Typography> 
          <Typography variant="body2" color="text.secondary">
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
        </Grid>
        
        <Box height={100}></Box>
        <Grid item xs={4}>
        <p>Hello</p>
        </Grid>
        
      </Grid>
      </>
    
  );
};

export default HomePage;
